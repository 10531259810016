import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getEngravingSectionListData,
} from "../../../store/slices/EngravingSection/engravingSectionSlice";
import { toast } from "react-toastify";
import { startTimer } from "../api";

const useEngraving = () => {
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const engravingSectionState = useSelector((state) => state.engravingSection);
  const engravingData = engravingSectionState.engravingSectionData;
  const [showDetail, setShowDetail] = useState(false);
  const [gliNumber, setGliNumber] = useState("");
  // const [certificateListData, setCertificateList] = useState();
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const dispatch = useDispatch();

  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: engravingSectionState.currentPageSize,
      // search: engravingSectionState.search,
      process_slug: slug,
    };
    dispatch(getEngravingSectionListData(params));

    // eslint-disable-next-line
  }, [
    // engravingSectionState.search,
    pendingPage,
    engravingSectionState.currentPageSize,
    slug,
  ]);

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;

    dispatch(updateConfig((state) => (state.search = searchValue)));
  };

  const handleSearch = () => {
    const searchValue = engravingSectionState?.search;
    dispatch(
      updateConfig((state) => {
        state.engravingSectionData.Pending = [];
      })
    );
    let params = {
      page: 1,
      per_page: engravingSectionState.currentPageSize,
      search: searchValue,
      process_slug: slug,
    };
    dispatch(getEngravingSectionListData(params));
  };

  const startTimerEngraving = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimer(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleChange = (item, key) => {
    dispatch(
      updateConfig((state) => {
        state.selectedCardId = item?._id;
      })
    );
    setGliNumber(item?.gli_number);
    setActiveCard({ id: item?._id, key: "Pending" });
    if (key === "Pending") {
      startTimerEngraving(item);
      setShowDetail(true);
      // getEngravingCertificate(params).then((response) => {
      //   // const certificateList = response?.data;
      //   // setCertificateList(certificateList);
      // });
    } else {
      setShowDetail(false);
      dispatch(
        updateConfig((state) => {
          state.receipt_id = item?._id;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      engravingData?.Pending?.length !== engravingSectionState?.total.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };

  return {
    gliNumber,
    showDetail,
    activeCard,
    engravingData,
    isLoading,
    totalLength: {
      Pending: engravingSectionState?.total?.Pending,
    },
    apiStatus: {
      Pending: engravingSectionState?.engravingSectionDataStatus,
    },
    handleSearch,
    handleSearchInput,
    handleLoadMore,
    handleChange,
    setIsLoading,
    // setCertificateList,
    // certificateListData,
  };
};

export default useEngraving;
