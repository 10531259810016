/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getQcAssignedList,
  getQcCompletedList,
  getQcMarkedList,
  getQcRejectedList,
  getQcPendingList,
  updateConfig,
} from "../../../store/slices/QC/QCSlice";
import { useSelector } from "react-redux";
import {
  geFormData,
  submitForm,
  getExcelFormData,
  getQCAddedData,
} from "./api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getStatusData } from "../../Global/AddStatus/api";
import { useGetDescriptionDataQuery } from "../../../store/queries/QC";
import { startTimer } from "../api";

const useQC = () => {
  const {
    qcData,
    showForm,
    showQcModal,
    formData,
    certificateID,
    certificateNumber,
    assignedID,
    search,
    showValues,
    itemID,
    total,
    qcPendingStatus,
    qcAssignedStatus,
    qcCompletedStatus,
    qcMarkedStatus,
    qcRejectedStatus,
    showGli,
    gliNumber,
    itemTypeCat,
    enterMoreModal,
    enterMoreFormModal,
    showMoreFormTable,
  } = useSelector((state) => state.qc);

  const [assignedPage, setAssignedPage] = useState(1);
  const [pendingPage, setPendingPage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [markedPage, setMarkedPage] = useState(1);
  const [rejectedPage, setRejectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [isNewDesc, setIsNewDesc] = useState(false);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [imagePreview, setImagePreview] = useState("");
  const reportRef = useRef(null);
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");

  const { data: descriptions } = useGetDescriptionDataQuery(
    {
      process_slug: slug,
      item_details_id: certificateID,
    },
    {
      skip: !certificateID,
    }
  );

  useEffect(() => {
    dispatch(
      getQcAssignedList({
        page: 1,
        per_page: 10,
        date: [],
        item_type: [],
        shop: [],
        status: [],
        process_slug: slug,
      })
    );
    dispatch(
      updateConfig((state) => {
        state.qcData.Passed = [];
        state.qcData.Pending = [];
        state.qcData.Marked = [];
        state.qcData.Rejected = [];
        state.showValues = false;
        state.showForm = false;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pendingPage !== 1) {
      dispatch(
        getQcPendingList({
          page: pendingPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);
  useEffect(() => {
    if (assignedPage !== 1) {
      dispatch(
        getQcAssignedList({
          page: assignedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPage]);

  useEffect(() => {
    if (completedPage !== 1) {
      dispatch(
        getQcCompletedList({
          page: completedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage]);

  useEffect(() => {
    if (markedPage !== 1) {
      dispatch(
        getQcMarkedList({
          page: markedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
          status: 5,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedPage]);

  useEffect(() => {
    if (rejectedPage !== 1) {
      dispatch(
        getQcRejectedList({
          page: rejectedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
          status: 4,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedPage]);

  let dynamicStructure = {};

  const formik = useFormik({
    initialValues: dynamicStructure,
    validate: (values) => {
      let errors = {};
      Object.keys(values || {}).forEach((key) => {
        // if (
        //   values[key] === null ||
        //   values[key] === undefined ||
        //   values[key] === ""
        // ) {
        //   errors[`${key}`] = `This field is required`;
        // }
        if (values?.status === undefined) {
          errors.status = "Select Status";
        }

        if (key === "image") {
          const image = values[key];
          if (image) {
            // Check image type
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!allowedTypes.includes(image.type)) {
              errors.image = "Image must be in JPEG or PNG format";
            }
            // Check image size (less than 2MB)
            const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
            if (image.size > maxSizeInBytes) {
              errors.image = "Image size must be less than 2MB";
            }
          }
        }
        if (key === "group") {
          const groupErrors = [];
          values[key].forEach((item, index) => {
            const itemErrors = {};

            // Check if "type" is required
            if (!item.type || item.type.trim() === "") {
              itemErrors.type = "Type is required";
            }
            // Add errors for the current group item to the groupErrors array
            if (Object.keys(itemErrors).length > 0) {
              groupErrors[index] = itemErrors;
            }
          });

          // Check if there are any errors for the group and assign them to the errors object
          if (groupErrors.length > 0) {
            errors[key] = groupErrors;
          }
        }
      });
      return errors;
    },
    onSubmit: (values) => {
      handleConfirm(values);
    },
  });

  useEffect(() => {
    dynamicStructure["status"] = 2;
    dynamicStructure["remarks"] = "";
    formik?.setFieldValue("status", 2);
    formik?.setFieldValue("remarks", "");
    // Iterate over the qc_fields array unconditionally
    if (formData?.length !== 0 && slug === "qc") {
      for (const field of formData?.qc_fields) {
        const slug = field?.slug;
        const value = field?.value ?? "";
        if (slug === "color") {
          const color = formData?.color?.filter(
            (val) =>
              val?.name?.toUpperCase()?.replace(/\s/g, "") ===
              value?.toUpperCase()?.replace(/\s/g, "")
          );
          formik?.setFieldValue(`color`, `${color?.[0]?.name ?? ""}`);
          dynamicStructure[slug] = color?.[0]?.name ?? "";
        } else if (slug === "clarity") {
          const clarity = formData?.clarity?.filter(
            (val) => val?.name?.toLowerCase() === value?.toLowerCase()
          );
          formik?.setFieldValue(`clarity`, `${clarity?.[0]?.name ?? ""}`);
          dynamicStructure[slug] = clarity?.[0]?.name ?? "";
        } else if (slug === "finish") {
          const finish = formData?.finish?.filter(
            (val) => val?.name?.toLowerCase() === value?.toLowerCase()
          );
          formik?.setFieldValue(`finish`, `${finish?.[0]?.name ?? ""}`);
          dynamicStructure[slug] = finish?.[0]?.name ?? "";
        } else if (slug === "shape") {
          const shape = formData?.shape?.filter(
            (val) => val?.name?.toLowerCase() === value?.toLowerCase()
          );
          formik?.setFieldValue(`shape`, `${shape?.[0]?.name ?? ""}`);
          dynamicStructure[slug] = shape?.[0]?.name ?? "";
        } else if (slug === "description") {
          setIsNewDesc(true);
          const desciption = {
            label: value,
            value: value,
          };
          formik?.setFieldValue(`description`, desciption);
          dynamicStructure[slug] = desciption?.value ?? "";
        } else {
          formik?.setFieldValue(`${field?.slug}`, `${field?.value ?? ""}`);
          dynamicStructure[slug] = value ?? "";
        }
      }
      // Check if cstone_sstone is 1 before adding center_stone_fields and side_stone_fields
      if (formData.cstone_sstone === 1) {
        for (const field of formData?.center_stone_fields) {
          const slug = field?.slug;
          const value = field?.value ?? "";
          if (slug === "color") {
            const color = formData?.color?.filter(
              (val) =>
                val?.name?.toLowerCase()?.replace(/\s/g, "") ===
                value?.toLowerCase()?.replace(/\s/g, "")
            );
            formik?.setFieldValue(
              `center_stone_color`,
              `${color?.[0]?.name ?? ""}`
            );
            dynamicStructure[`center_stone_${slug}`] = color?.[0]?.name ?? "";
          } else if (slug === "clarity") {
            const clarity = formData?.clarity?.filter(
              (val) => val?.name?.toLowerCase() === value?.toLowerCase()
            );
            formik?.setFieldValue(
              `center_stone_clarity`,
              `${clarity?.[0]?.name ?? ""}`
            );
            dynamicStructure[`center_stone_${slug}`] = clarity?.[0]?.name ?? "";
          } else if (slug === "finish") {
            const finish = formData?.finish?.filter(
              (val) => val?.name?.toLowerCase() === value?.toLowerCase()
            );
            formik?.setFieldValue(
              `center_stone_finish`,
              `${finish?.[0]?.name ?? ""}`
            );
            dynamicStructure[`center_stone_${slug}`] = finish?.[0]?.name ?? "";
          } else if (slug === "shape") {
            const shape = formData?.shape?.filter(
              (val) => val?.name?.toLowerCase() === value?.toLowerCase()
            );
            formik?.setFieldValue(
              `center_stone_shape`,
              `${shape?.[0]?.name ?? ""}`
            );
            dynamicStructure[`center_stone_${slug}`] = shape?.[0]?.name ?? "";
          } else {
            dynamicStructure[`center_stone_${slug}`] = value;
            formik?.setFieldValue(`center_stone_${slug}`, `${value ?? ""}`);
          }
        }

        for (const field of formData?.side_stone_fields) {
          const slug = field?.slug;
          const value = field?.value ?? "";
          if (slug === "color") {
            const color = formData?.color?.filter(
              (val) =>
                val?.name?.toLowerCase()?.replace(/\s/g, "") ===
                value?.toLowerCase()?.replace(/\s/g, "")
            );
            formik?.setFieldValue(
              `side_stone_color`,
              `${color?.[0]?.name ?? ""}`
            );
            dynamicStructure[`side_stone_${slug}`] = color?.[0]?.name ?? "";
          } else if (slug === "clarity") {
            const clarity = formData?.clarity?.filter(
              (val) => val?.name?.toLowerCase() === value?.toLowerCase()
            );
            formik?.setFieldValue(
              `side_stone_clarity`,
              `${clarity?.[0]?.name ?? ""}`
            );
            dynamicStructure[`side_stone_${slug}`] = clarity?.[0]?.name ?? "";
          } else if (slug === "finish") {
            const finish = formData?.finish?.filter(
              (val) => val?.name?.toLowerCase() === value?.toLowerCase()
            );
            formik?.setFieldValue(
              `side_stone_finish`,
              `${finish?.[0]?.name ?? ""}`
            );
            dynamicStructure[`side_stone_${slug}`] = finish?.[0]?.name ?? "";
          } else if (slug === "shape") {
            const shape = formData?.shape?.filter(
              (val) => val?.name?.toLowerCase() === value?.toLowerCase()
            );
            formik?.setFieldValue(
              `side_stone_shape`,
              `${shape?.[0]?.name ?? ""}`
            );
            dynamicStructure[`side_stone_${slug}`] = shape?.[0]?.name ?? "";
          } else {
            dynamicStructure[`side_stone_${slug}`] = value;
            formik?.setFieldValue(`side_stone_${slug}`, `${value ?? ""}`);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (formData && slug === "qc") {
      formik?.setErrors({});
      formik.setValues(dynamicStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const startTimerQC = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimer(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleCardClick = async (item, key) => {
    Object?.keys(formik?.errors ?? {})?.forEach((val) => {
      formik?.setFieldError(val, "");
      formik?.setFieldTouched(val, false);
    });
    setActiveCard({ id: item?._id, key: key });
    dispatch(
      updateConfig((state) => {
        state.item = item;
        state.showMoreFormTable = false;
        state.showVersionTwoTable = false;
        state.editForm = false;
      })
    );
    if (key === "Assigned") {
      startTimerQC(item);
      dispatch(
        updateConfig((state) => {
          state.assignedID = item?._id;
          state.qcData.Passed = [];
          state.qcData.Pending = [];
          state.qcData.Marked = [];
          state.qcData.Rejected = [];
          state.showValues = false;
          state.showForm = false;
          state.showGli = true;
          state.gliNumber = item?.gli_number;
          setImagePreview("");
        })
      );
      dispatch(
        getQcPendingList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getQcCompletedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getQcMarkedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getQcRejectedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 4,
        })
      );
    } else if (key === "Pending") {
      setImagePreview("");
      getStatusData().then((response) => {
        if (response?.data?.status_code === 200) {
          setStatusData(response?.data?.data);
        }
      });
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.certificateNumber = item?.certificate_number;
          state.itemID = item?.gli_number;
          state.itemTypeCat = item?.item_type_category ?? "";
        })
      );
      let data = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      geFormData(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            dispatch(
              updateConfig((state) => {
                state.formData = response?.data?.data;
                state.showValues = false;
                state.showForm = true;
                state.showGli = false;
              })
            );
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => toast.error(error));
      if (slug !== "qc") {
        getQCAddedData(data).then((response) => {
          if (response?.data?.status_code === 200) {
            if (slug !== "qc") {
              for (const field of response?.data?.data?.qc_fields) {
                const slug = field?.slug;
                const value = field?.value;
                if (slug === "color") {
                  const result = formData?.color?.filter(
                    ({ name }) =>
                      name?.toLowerCase()?.replace(/\s/g, "") ===
                      value?.toLowerCase()?.replace(/\s/g, "")
                  )?.[0];
                  formik?.setFieldValue(`${slug}`, result?.name);
                } else if (slug === "clarity") {
                  const result = formData?.clarity?.filter(
                    ({ name }) =>
                      name?.toLowerCase()?.replace(/\s/g, "") ===
                      value?.toLowerCase()?.replace(/\s/g, "")
                  )?.[0];
                  formik?.setFieldValue(`${slug}`, result?.name ?? "");
                } else if (slug === "grade") {
                  const result = formData?.grade?.filter(
                    ({ name }) =>
                      name?.toLowerCase()?.replace(/\s/g, "") ===
                      value?.toLowerCase()?.replace(/\s/g, "")
                  )?.[0];
                  formik?.setFieldValue(`${slug}`, result?.name ?? "");
                } else if (slug === "finish") {
                  const result = formData?.finish?.filter(
                    ({ name }) =>
                      name?.toLowerCase()?.replace(/\s/g, "") ===
                      value?.toLowerCase()?.replace(/\s/g, "")
                  )?.[0];
                  formik?.setFieldValue(`${slug}`, result?.name ?? "");
                } else if (slug === "shape") {
                  const result = formData?.shape?.filter(
                    ({ name }) =>
                      name?.toLowerCase()?.replace(/\s/g, "") ===
                      value?.toLowerCase()?.replace(/\s/g, "")
                  )?.[0];
                  formik?.setFieldValue(`${slug}`, result?.name ?? "");
                } else {
                  formik?.setFieldValue(
                    `${field?.slug}`,
                    `${field?.value ?? ""}`
                  );
                }
                dynamicStructure[slug] = value ?? "";
                dynamicStructure["status"] = "";
                setIsNewDesc(true);
              }
              // Check if cstone_sstone is 1 before adding center_stone_fields and side_stone_fields
              if (response?.data?.data?.cstone_sstone === 1) {
                for (const field of response?.data?.data?.center_stone_fields) {
                  const slug = field?.slug;
                  dynamicStructure[`center_stone_${slug}`] = "";
                  formik?.setFieldValue(
                    `center_stone_${slug}`,
                    `${field?.value ?? ""}`
                  );
                }

                for (const field of response?.data?.data?.side_stone_fields) {
                  const slug = field?.slug;
                  dynamicStructure[`side_stone_${slug}`] = "";
                  formik?.setFieldValue(
                    `side_stone_${slug}`,
                    `${field?.value ?? ""}`
                  );
                }
              }
            }
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    } else if (key === "Passed") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.certificateNumber = item?.certificate_number;
          state.itemID = item?.gli_number;
          state.showCompare = false;
          state.showGli = false;
          state.data = [{ name: "Version 1" }];
          state.activeId = 1;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            if (response?.data?.data?.updated_values.length !== 0) {
              const newData = response.data.data.updated_values.map(
                (_, index) => ({
                  name: `Version ${index + 2}`,
                  disabled: true, // Set the disabled property to true for all versions
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.data = [
                    { name: "Version 1", disabled: true },
                    ...newData,
                  ]; // Disable all versions in the data array
                })
              );
            }
            dispatch(
              updateConfig((state) => {
                state.excelFormData = response?.data?.data?.graded_values;
                state.excelCopyPermission =
                  response?.data?.data?.create_copy_permission;
                state.excelEditedFormData =
                  response?.data?.data?.updated_values.length === 0
                    ? (state.excelFormData =
                        response?.data?.data?.graded_values)
                    : response?.data?.data?.updated_values;
                state.showValues = true;
                state.showForm = false;
                state.dropdownValues =
                  response?.data?.data?.dropdown_values ?? {};
              })
            );
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => toast.error(error));
    } else if (key === "Marked") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.certificateNumber = item?.certificate_number;
          state.itemID = item?.gli_number;
          state.showCompare = false;
          state.showGli = false;
          state.data = [{ name: "Version 1" }];
          state.activeId = 1;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            if (response?.data?.data?.updated_values.length !== 0) {
              const newData = response.data.data.updated_values.map(
                (_, index) => ({
                  name: `Version ${index + 2}`,
                  disabled: true, // Set the disabled property to true for all versions
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.data = [
                    { name: "Version 1", disabled: true },
                    ...newData,
                  ]; // Disable all versions in the data array
                })
              );
            }
            dispatch(
              updateConfig((state) => {
                state.excelFormData = response?.data?.data?.graded_values;
                state.excelCopyPermission =
                  response?.data?.data?.create_copy_permission;
                state.excelEditedFormData =
                  response?.data?.data?.updated_values.length === 0
                    ? (state.excelFormData =
                        response?.data?.data?.graded_values)
                    : response?.data?.data?.updated_values;
                state.showValues = true;
                state.showForm = false;
                state.dropdownValues =
                  response?.data?.data?.dropdown_values ?? {};
              })
            );
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => toast.error(error));
    } else if (key === "Rejected") {
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.certificateNumber = item?.certificate_number;
          state.itemID = item?.gli_number;
          state.showCompare = false;
          state.showGli = false;
          // state.data = [{ name: "Version 1" }];
          state.activeId = 1;
        })
      );
      let data2 = {
        item_details_id: item?._id,
        item_type_id: item?.item_type_id,
        process_slug: slug,
      };
      getExcelFormData(data2)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            if (response?.data?.data?.updated_values.length !== 0) {
              const newData = response.data.data.updated_values.map(
                (_, index) => ({
                  name: `Version ${index + 2}`,
                  disabled: true, // Set the disabled property to true for all versions
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.data = [
                    { name: "Version 1", disabled: true },
                    ...newData,
                  ]; // Disable all versions in the data array
                })
              );
            }
            dispatch(
              updateConfig((state) => {
                state.excelFormData = response?.data?.data?.graded_values;
                state.excelCopyPermission =
                  response?.data?.data?.create_copy_permission;
                state.excelEditedFormData =
                  response?.data?.data?.updated_values.length === 0
                    ? (state.excelFormData =
                        response?.data?.data?.graded_values)
                    : response?.data?.data?.updated_values;
                state.showValues = true;
                state.showForm = false;
                state.dropdownValues =
                  response?.data?.data?.dropdown_values ?? {};
              })
            );
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => toast.error(error));
    }
  };

  const handleDescChange = (e, slug) => {
    if (e?.__isNew__) {
      setIsNewDesc(true);
      formik?.setFieldValue(slug, e?.label);
    } else {
      setIsNewDesc(false);
      formik?.setFieldValue(slug, e?.label);
    }
  };
  const getFieldError = (fieldName) => {
    if (formik?.touched?.[fieldName] && formik?.errors?.[fieldName]) {
      return formik?.errors?.[fieldName];
    }
    return "";
  };

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;

    dispatch(updateConfig((state) => (state.search = searchValue)));
  };

  // Inside your component
  const handleSearch = () => {
    const searchValue = search;

    dispatch(
      updateConfig((state) => {
        state.qcData.Pending = [];
        state.qcData.Passed = [];
        state.qcData.Assigned = [];
        state.qcData.Marked = [];
        state.qcData.Rejected = [];
      })
    );
    dispatch(
      getQcAssignedList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
    if (assignedID) {
      dispatch(
        getQcCompletedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getQcPendingList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getQcMarkedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getQcRejectedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 4,
        })
      );
    }
  };

  const handleConfirm = (values) => {
    let data = {
      process_slug: slug,
      item_details_id: certificateID,
      ...values,
    };

    let formDatas = new FormData();
    for (let key in data) {
      if (key.startsWith("center_stone")) {
        const formattedKey = key.replace("center_stone_", ""); // Remove the prefix
        formDatas.append(`center_stone[${formattedKey}]`, data[key]);
      } else if (key.startsWith("side_stone")) {
        const formattedKey = key.replace("side_stone_", ""); // Remove the prefix
        formDatas.append(`side_stone[${formattedKey}]`, data[key]);
      } else {
        let statusType =
          values?.status == 5
            ? "marked"
            : values?.status == 4
            ? "rejected"
            : "passed";
        // For other keys, append them as they are
        if (key === "group") {
          // eslint-disable-next-line array-callback-return
          values?.group?.map((val, index) => {
            if (val?.type !== "") {
              let type = formData?.type?.filter(
                (typ) => typ?.name === val?.type
              );
              formDatas?.append(`${statusType}[${index}][type]`, val?.type);
              formDatas?.append(
                `${statusType}[${index}][type_id]`,
                type?.[0]?.id
              );
            }
            if (val?.type === "OTHERS") {
              formDatas?.append(`${statusType}[${index}][others]`, val?.others);
            }

            if (val?.count !== "") {
              formDatas?.append(`${statusType}[${index}][count]`, val?.count);
            }

            if (val?.percentage !== "") {
              formDatas?.append(
                `${statusType}[${index}][percentage]`,
                val?.percentage
              );
            }
          });
        } else {
          formDatas.append(key, data[key]);
          formDatas?.delete("status");
          formDatas?.delete("remarks");
          formDatas?.append("remarks", values?.remarks);
          formDatas?.append("status", values?.status);
        }
      }
    }

    if (values?.status === 2) {
      formDatas?.delete("reason");
      formDatas?.delete("image");
      formDatas?.delete("marked[type]");
      formDatas?.delete("rejected[type]");
      formDatas?.delete("marked[others]");
      formDatas?.delete("rejected[others]");
      formDatas?.delete("marked[count]");
      formDatas?.delete("rejected[count]");
      formDatas?.delete("marked[percentage]");
      formDatas?.delete("rejected[percentage]");
      formDatas?.delete("group");
    }
    submitForm(formDatas)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Success");
          setImagePreview("");
          dispatch(updateConfig((state) => (state.showForm = false)));
          dispatch(
            updateConfig((state) => {
              state.qcData.Pending = [];
              state.qcData.Passed = [];
              state.qcData.Marked = [];
              state.qcData.Rejected = [];
              // state.enterMoreModal = true;
            })
          );

          if (
            itemTypeCat === "diamond_jewellery" ||
            itemTypeCat === "mixed_jewellery" ||
            itemTypeCat === "diamond_loose" ||
            itemTypeCat === "center_and_side_stone" ||
            itemTypeCat === "other"
          ) {
            dispatch(updateConfig((state) => (state.enterMoreModal = true)));
          }

          formik?.resetForm();
          formik?.setErrors({});
          dispatch(
            getQcPendingList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getQcCompletedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getQcMarkedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 5,
            })
          );
          dispatch(
            getQcRejectedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 4,
            })
          );
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(error);
        setImagePreview("");
      });
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      qcData?.Pending?.length !== total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Assigned" &&
      qcData?.Assigned?.length !== total?.Assigned
    ) {
      setAssignedPage(assignedPage + 1);
    }
    if (mouseHover === "Passed" && qcData?.Passed?.length !== total?.Passed) {
      setCompletedPage(completedPage + 1);
    }
    if (mouseHover === "Marked" && qcData?.Marked?.length !== total?.Marked) {
      setMarkedPage(markedPage + 1);
    }
    if (
      mouseHover === "Rejected" &&
      qcData?.Rejected?.length !== total?.Rejected
    ) {
      setRejectedPage(rejectedPage + 1);
    }
  };

  const handleImageChange = (event) => {
    const image = event?.target?.files[0];
    formik.setFieldValue("image", image);
    const file = image;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleStatusChange = (value) => {
    formik?.setFieldValue("status", value?.id || "");
    if (value?.id !== 2) {
      formik?.setFieldValue("group", [
        {
          type: "",
          count: "",
          percentage: "",
          others: "",
        },
      ]);
    } else {
      formik?.setFieldValue("group", []);
    }
  };

  const handleAddRemarkkCardClick = () => {
    let { group } = formik?.values;
    group = [
      ...group,
      {
        type: "",
        count: "",
        percentage: "",
        others: "",
      },
    ];
    formik.setFieldValue("group", group);
  };
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qcPendingStatus, qcAssignedStatus, qcCompletedStatus]);

  const handleEnterMoreModalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.enterMoreModal = false;
        state.showMoreFormTable = true;
      })
    );
  };

  const handleEnterMoreYes = () => {
    dispatch(
      updateConfig((state) => {
        state.enterMoreModal = false;
        state.enterMoreFormModal = true;
      })
    );
  };

  const handleEnterMoreFormModalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.enterMoreFormModal = false;
        state.showMoreFormTable = true;
      })
    );
  };

  return {
    isLoading,
    qcData,
    showForm,
    showQcModal,
    formik,
    showValues,
    showGli,
    itemID,
    gliNumber,
    formData,
    descriptions: descriptions?.data,
    certificateNumber,
    totalLength: {
      Pending: total?.Pending,
      Assigned: total?.Assigned,
      Passed: total?.Passed,
      Rejected: total?.Rejected,
      Marked: total?.Marked,
    },
    statusData,
    activeCard,
    imagePreview,
    reportRef,
    apiStatus: {
      Pending: qcPendingStatus,
      Assigned: qcAssignedStatus,
      Passed: qcCompletedStatus,
      Rejected: qcRejectedStatus,
      Marked: qcMarkedStatus,
    },
    enterMoreModal,
    enterMoreFormModal,
    showMoreFormTable,
    isNewDesc,
    handleDescChange,
    handleStatusChange,
    handleEnterMoreModalClose,
    handleAddRemarkkCardClick,
    handleEnterMoreFormModalClose,
    handleSearch,
    handleSearchInput,
    handleEnterMoreYes,
    handleImageChange,
    handleLoadMore,
    setIsLoading,
    getFieldError,
    handleCardClick,
    handleConfirm,
  };
};

export default useQC;
