import { useDispatch } from "react-redux";
import { debounce } from "../../../utils/hooks/useDebounce";
import {
  getPhotoSectionRecheckList,
  updateConfig,
} from "../../../store/slices/PhotoSection/PhotoSectionSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { startTimerRecheck } from "../api";
import { toast } from "react-toastify";

const usePhotoSectionRecheck = () => {
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const photoSectionState = useSelector((state) => state.photoSection);
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      per_page: 10,
      process_slug: slug,
    };
    dispatch(getPhotoSectionRecheckList(params));
    // eslint-disable-next-line
  }, [
    photoSectionState.currentPage,
    photoSectionState.currentPageSize,
    pendingPage,
  ]);

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;
    dispatch(updateConfig((state) => (state.searchRecheck = searchValue)));
  };

  const startTimerPhoto = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimerRecheck(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleCardClick = (e) => {
    setActiveCard({ id: e._id, key: "Pending" });
    startTimerPhoto(e);
    dispatch(
      updateConfig((state) => {
        state.showTable = true;
        state.selectedId = e?._id;
        state.gli_id = e?.gli_number;
        state.currentPage = 1;
      })
    );
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      photoSectionState?.cardRecheckData?.Pending?.length !==
        photoSectionState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };

  const handleSearch = () => {
    const searchValue = photoSectionState?.searchRecheck;

    dispatch(
      updateConfig((state) => {
        state.cardRecheckData.Pending = [];
      })
    );
    dispatch(
      getPhotoSectionRecheckList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
  };
  return {
    activeCard,
    isLoading,
    photoSectionState,
    cardRecheckData: photoSectionState?.cardRecheckData,
    totalLength: { Pending: photoSectionState.total.Pending },
    apiStatus: { Pending: photoSectionState?.RecheckPendingCardStatus },
    setIsLoading,
    handleSearchInput,
    handleSearch,
    handleCardClick,
    handleLoadMore,
  };
};

export default usePhotoSectionRecheck;
