import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const branchIdParams = params?.branch_id
      ? params?.branch_id?.map((id) => `branch_ids[]=${id}`).join("&")
      : "";

    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("receipt_id")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&per_page=${params?.page_size || "10"}&${getParams("search")}page=${
            params?.page || 1
          }&${getParams("process_slug")}&${branchIdParams}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams("search")}${getParams("sort_by")}${branchIdParams}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const reports = createApi({
  reducerPath: "reportsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["reports"],
  endpoints: (builder) => ({
    // form data
    getInternalReportData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/internal-reports/dwsr-stock-receipt-list`,
      }),
      providesTags: ["reports"],
    }),
    getInternalReportSummaryData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/internal-reports/dwsr-summery`,
      }),
      providesTags: ["reports"],
    }),
    updateInternalReportTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),
      invalidatesTags: ["reports"],
    }),
    getReportBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/internal-reports/basic-data`,
      }),
    }),
    getReportTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/internal-reports/percentage`,
      }),
    }),
    getGLIReportTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/internal-reports/receipt-base`,
      }),
    }),
    getGLIReportDetailsTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `internal-reports/receipt-detail`,
      }),
    }),
    getOtherReportBranchTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/internal-reports/other-reports/branch-or-shop-and-supplier/stock-receipt-list`,
      }),
    }),
    getOtherReportEmployeeTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/internal-reports/other-reports/employee/list`,
      }),
    }),
    getOtherReportEmployeeDetailsTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/internal-reports/other-reports/employee/stock-receipt-list`,
      }),
    }),
    getOtherReportDepartmentTableData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `internal-reports/department`,
      }),
    }),
    getOtherReportBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/internal-reports/other-reports/basic-data`,
      }),
    }),
  }),
});

export const {
  useGetReportBasicDataQuery,
  useGetReportTableDataQuery,
  useGetInternalReportDataQuery,
  useGetGLIReportTableDataQuery,
  useGetGLIReportDetailsTableDataQuery,
  useGetOtherReportBasicDataQuery,
  useGetOtherReportBranchTableDataQuery,
  useGetOtherReportEmployeeTableDataQuery,
  useGetOtherReportDepartmentTableDataQuery,
  useGetOtherReportEmployeeDetailsTableDataQuery,
  useUpdateInternalReportTableHeadDataMutation,
  useGetInternalReportSummaryDataQuery
} = reports;
