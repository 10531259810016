import { useSelector } from "react-redux";
import {
  useGetBillingReissueListDataQuery,
  useUpdateBillReissueTableHeadDataMutation,
} from "../../../../store/queries/Billing";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/Billing/billingReissueSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const useBillingReissue = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const billingReissueState = useSelector((state) => state.billingReissue);
  const {
    data: billingReissueList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBillingReissueListDataQuery({
    page_size: billingReissueState.currentPageSize,
    page: billingReissueState.currentPage,
    sort_by: billingReissueState.sortBy,
    sort_order: billingReissueState.sortOrder,
    start: billingReissueState.startDate,
    end: billingReissueState.endDate,
    search: billingReissueState.search,
  });

  const [updateBillingReissueFields] =
    useUpdateBillReissueTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = billingReissueList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [billingReissueList]);
  useEffect(() => {
    if (billingReissueState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [billingReissueState.clearSelection]);

  //   const handleModalClick = () => {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.showAddBranchModal = !state.showAddBranchModal;
  //         state.activeTab = "Basic";
  //         state.logoPreview = "";
  //         state.branchData = [];
  //         state.isEdit = false;
  //       })
  //     );
  //   };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  //   const handleDeleteModal = (e) => {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.showDeleteModal = !state.showDeleteModal;
  //         state.selectedBranch = e?.[0];
  //       })
  //     );
  //   };

  //   const handleEditAction = (e) => {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.activeTab = "Basic";
  //         state.showAddBranchModal = true;
  //         state.selectedBranch = e?.[0];
  //         state.isEdit = true;
  //       })
  //     );
  //   };

  const handleSearchDebounced = debounce((value, dispatch) => {
    dispatch(
      updateConfig((state) => {
        state.search = value;
        state.currentPage = 1;
      })
    );
  }, 1000);

  const handleSearch = (e) => {
    handleSearchDebounced(e.target.value, dispatch);
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (billingReissueState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            billingReissueState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleReissueCertificate = (e) => {
    dispatch(
      updateConfig((state) => {
        state.selectedId = e?.[0];
      })
    );
    localStorage.setItem("receiptId", e?.[0]);
    navigate("/billing/reissue/certificates-list");
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    billingReissueList,
    billingReissueState,
    updateBillingReissueFields,
    handleReissueCertificate,
    handleEditColumnsClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useBillingReissue;
