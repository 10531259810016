import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../Global/OrderColumn";
import useBillingReissue from "./useBillingReissue";

const BillingReissueList = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    billingReissueList,
    billingReissueState,
    updateBillingReissueFields,
    handleReissueCertificate,
    handleEditColumnsClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useBillingReissue();
  return (
    <div>
      <HeadingGroup title={"Billing Reissue"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
        />
        <Table
          multiSelect={false}
          data={billingReissueList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          assignIcon={
            <span className="material-symbols-outlined">check_circle</span>
          }
          handleSort={handleSort}
          clear={billingReissueState.clearSelection}
          getRow={getRow}
          loading={isFetching}
          perpage={billingReissueState?.currentPageSize}
          assignable={true}
          assignText={"Submit"}
          handleAssign={handleReissueCertificate}
          editable={false}
          deletable={false}
          showCheckBox={true}
        />
        {billingReissueList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={billingReissueState?.currentPage}
            totalPageCount={Math.ceil(
              billingReissueList?.data?.total_count /
                billingReissueState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={billingReissueList?.data?.fields}
              moduleId={billingReissueList?.data?.module_id}
              updateData={updateBillingReissueFields}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default BillingReissueList;
