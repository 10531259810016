import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { StaticHtmlTemplateC } from "./staticHtmlTemplateC";
import { StaticHtmlTemplateD } from "./staticHtmlTemplateD";
import { StaticHtmlTemplate } from "./staticHtmlTemplate";
import { StaticHtmlTemplateE } from "./staticHtmlTemplateE";
const NewVisitingDuplex = React.forwardRef(({ vistingDuplexRef }, ref) => {
  const { printingData, card_size } = useSelector((state) => state.printing);

  const splitPrintingData = (data) => {
    const chunkSize = 10;
    const chunks = [];
    for (let i = 0; i < data?.length; i += chunkSize) {
      chunks?.push(data?.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunks = splitPrintingData(printingData);

  return (
    <div ref={vistingDuplexRef}>
      <title>DHC Card</title>
      <div style={{ boxSizing: "border-box" }}>

        {
          chunks?.map((chunk) =>(
            <>
            <div style={{ height: "1113.5px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "start",
              columnGap: 62,
              rowGap: 14,
              paddingTop: 12,
              paddingLeft: 8,
              paddingRight: 8,
              fontFamily: "'Graphik',san-serif",
              width: 720,
              fontSize: 8,
            }}
          >
            {chunk?.map((value, mainIndex) => {
              let template;

              if (value?.center_stone_side_stone) {
                template = <StaticHtmlTemplateC value={value} />;
              } else if (parseInt(value?.qr_and_image_format) === 3) {
                template = <StaticHtmlTemplateD value={value} />;
              } else {
                card_size === 1
                  ? (template = <StaticHtmlTemplate value={value} />)
                  : (template = <StaticHtmlTemplateE value={value} />);
              }

              return <Fragment key={mainIndex}>{template}</Fragment>;
            })}
          </div>
        </div>
        {/* <div className="html2pdf__page-break"></div> */}

        {/* Back */}
        <div style={{ height: "1113.5px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "start",
              columnGap: 52.37,
              flexDirection: "row-reverse",
              paddingRight: 1.87,
              paddingLeft: 4.5,
              rowGap: 7.79,
              paddingTop: 8.5,
              width: 720,
              fontSize: 8,
            }}
          >
            {chunk?.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#ffffff",
                    maxWidth: 330.63,
                    minWidth: 330.63,
                    width: 330.63,
                    maxHeight: 213.21,
                    minHeight: 213.21,
                    height: 213.21,
                    lineHeight: 0,
                    border: "1px solid #f3f3f3",
                    padding: 0,
                    margin: 0,
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={value?.back_image}
                    width={330.63}
                    height={213.21}
                    alt="back_image"
                    style={{
                      width: "100%",
                      height: "100%",
                      lineHeight: 0,
                      padding: 0,
                      margin: 0,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
            </>
          ))
        }
      </div>
    </div>
  );
});

export default NewVisitingDuplex;
