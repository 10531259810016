import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentFilter: "all",
  certificate: {
    currentPageSize: 20,
    currentPage: 1,
  },
  recheckCertificate: {
    currentPageSize: 20,
    currentPage: 1,
  },
  currentPageSize: 10,
  currentPage: 1,
  sortBy: "",
  sortOrder: "",
  search: "",
  searchRecheck: "",
  showAddEngravingSecModal: false,
  showPassword: false,
  showDeleteModal: false,
  submissionStatus: "",
  selectedCardId: "",
  selectedEngravingSection: "",
  isEdit: false,
  engravingSectionData: {
    Pending: [],
  },
  engravingSectionRecheckData: {
    Pending: [],
  },
  logoPreview: "",
  engravingSectionDataStatus: "idle",
  engravingSectionRecheckDataStatus: "idle",
  total: {
    Pending: "",
  },
  clear: false,
};

export const getEngravingSectionListData = createAsyncThunk(
  "employee/engraving",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getEngravingSectionRecheckListData = createAsyncThunk(
  "employee/engraving/recheck",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const engravingSectionSlice = createSlice({
  name: "engravingSection",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getEngravingSectionListData.pending]: (state) => {
      state.engravingSectionDataStatus = "pending";
    },
    [getEngravingSectionListData.fulfilled]: (state, action) => {
      // if (state.engravingSectionData.Pending.length) {
      //   state.engravingSectionData.Pending.concat(
      //     action.payload.data.data.data
      //   );
      // } else {
      //   state.engravingSectionData.Pending = action.payload.data.data.data;
      // }
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.engravingSectionData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.engravingSectionData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.engravingSectionDataStatus = "fulfilled";
    },
    [getEngravingSectionListData.rejected]: (state) => {
      state.engravingSectionDataStatus = "failed";
    },
    //Engraving Section Recheck List Data

    [getEngravingSectionRecheckListData.pending]: (state) => {
      state.engravingSectionRecheckDataStatus = "pending";
    },
    [getEngravingSectionRecheckListData.fulfilled]: (state, action) => {
      // if (state.engravingSectionRecheckData.Pending.length) {
      //   state.engravingSectionRecheckData.Pending.concat(
      //     action.payload.data.data.data
      //   );
      // } else {
      //   state.engravingSectionRecheckData.Pending =
      //     action.payload.data.data.data;
      // }
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.engravingSectionRecheckData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.engravingSectionRecheckData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.engravingSectionRecheckDataStatus = "fulfilled";
    },
    [getEngravingSectionRecheckListData.rejected]: (state) => {
      state.engravingSectionRecheckDataStatus = "failed";
    },
  },
});

export const { updateConfig, resetConfig } = engravingSectionSlice.actions;

export default engravingSectionSlice.reducer;
