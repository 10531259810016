import React from "react";
import { useSelector } from "react-redux";
import { StaticHtmlTemplate } from "./staticHtmlTemplate";
import { StaticHtmlTemplateC } from "./staticHtmlTemplateC";
import { StaticHtmlTemplateD } from "./staticHtmlTemplateD";
import { StaticHtmlTemplateE } from "./staticHtmlTemplateE";

const NewFrontCard = React.forwardRef(({ visitingOneFrontPrintRef }, ref) => {
  const { printingData, card_size } = useSelector((state) => state.printing);

  const splitPrintingData = (data) => {
    
    const chunkSize = 10;
    const chunks = [];
    for (let i = 0; i < data?.length; i += chunkSize) {
      chunks?.push(data?.slice(i, i + chunkSize));
    }
    return chunks;
  };
  const chunks = splitPrintingData(printingData);

  //end to change the length of key

  return (
    <div ref={visitingOneFrontPrintRef}>
      {chunks?.map((chunk, chunkIndex) => (
        <div key={chunkIndex} style={{ height: "1113.5px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: 62,
              rowGap: 14,
              paddingTop: 12,
              paddingLeft: 8,
              paddingRight: 8,
              // fontFamily: "Graphik",
              // fontWeight:700,
              width: 720,
              fontSize: 8,
              
            }}
          >
            <title>DHC Card</title>
            {chunk?.map((value, mainIndex) => {
              let template;
              if (value?.center_stone_side_stone) {
                template = <StaticHtmlTemplateC value={value} />;
              } else if (parseInt(value?.qr_and_image_format) === 3) {
                template = <StaticHtmlTemplateD value={value} />;
              } else {
                card_size === 1
                  ? (template = <StaticHtmlTemplate value={value} />)
                  : (template = <StaticHtmlTemplateE value={value} />);
              }

              return (
                <div key={mainIndex} className="item-class">
                  {template}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
});

export default NewFrontCard;
