import React from "react";
import Style from "./Packing.module.scss";
import { SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import GliCardListing from "../../Global/GliCardLIsting";
import usePacking from "./usePacking";
import PackingDetails from "./PackingDetails";

const EmployeePackingRecheck = () => {
  const {
    states,
    isLoading,
    showDetail,
    activeCard,
    totalLength,
    apiStatus,
    detailId,
    handleChange,
    handleInputSearch,
    handleSearch,
    setIsLoading,
    handleLoadMore,
  } = usePacking();

  return (
    <>
      <div className="row pro-mb-4">
        <div className="col-auto">
          <SearchFilters
            showDateRange={false}
            onSearchInput={handleInputSearch}
            showFilters={true}
            showActions={false}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
          />
        </div>
        <div className={`col-auto`}>
          <button
            className="pro-btn pro-btn-primary lg"
            onClick={handleSearch}
            type="button"
          >
            <span className="material-symbols-outlined">search</span>
            Search
          </button>
        </div>
      </div>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-2 col-3 ${Style.card_main_wrapper} `}>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              {states.length === 0 ? null : (
                <GliCardListing
                  data={states}
                  activeCard={activeCard}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  totalLength={totalLength}
                  handleLoadMore={handleLoadMore}
                  setIsLoading={setIsLoading}
                  apiStatus={apiStatus}
                  refBottom
                />
              )}
            </div>
          </div>

          <div
            className={`col-xxl-10 col-9 pro-pe-0 ${Style.table_main_wrapper}`}
          >
            <div className={`${Style.table_flow_wrapper} pro-pe-3`}>
              {showDetail === true ? (
                <PackingDetails id={detailId} />
              ) : (
                <h2>Select One</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePackingRecheck;
