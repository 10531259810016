/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getCVDAssignedList,
  getCVDCompletedList,
  getCVDPendingList,
  getCVDMarkedList,
  getCVDRejectedList,
} from "../../../store/slices/CVD/CVDSlice";
import { updateConfig } from "../../../store/slices/CVD/CVDSlice";
import { getCVDReport, getFields, startTimmer, submitForm } from "./api";
import { toast } from "react-toastify";
import { getStatusData } from "../../Global/AddStatus/api";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { startTimer } from "../api";

const useCVD = () => {
  const [timer, setTimer] = useState(true);
  const [isLoading, setIsLoading] = useState();
  const [assignedPage, setAssignedPage] = useState(1);
  const [pendingPage, setPendingPage] = useState(1);
  const [completedPage, setCompletedPage] = useState(1);
  const [markedPage, setMarkedPage] = useState(1);
  const [rejectedPage, setRejectedPage] = useState(1);
  const [statusData, setStatusData] = useState([]);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const form1PrintRef = useRef(null);
  const form2PrintRef = useRef(null);

  const {
    cvdData,
    showForm,
    certificateID,
    assignedID,
    formType,
    showValues,
    allValues,
    valueType,
    itemID,
    total,
    search,
    showGli,
    gliNumber,
    showCvdConfirmModal,
    cvdPendingStatus,
    cvdAssignedStatus,
    cvdCompletedStatus,
    cvdRejectedStatus,
    cvdMarkedStatus,
  } = useSelector((state) => state.cvd);
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  useEffect(() => {
    dispatch(
      getCVDAssignedList({
        page: assignedPage,
        per_page: 10,
        date: [],
        item_type: [],
        shop: [],
        status: [],
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPage]);

  const startTimmerCVD = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimer(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleCardClick = (item, key) => {
    setActiveCard({ id: item?._id, key: key });
    if (key === "Assigned") {
      startTimmerCVD(item);
      dispatch(
        updateConfig((state) => {
          state.assignedID = item?._id;
          state.cvdData.Pending = [];
          state.cvdData.Passed = [];
          state.cvdData.Rejected = [];
          state.cvdData.Marked = [];
          state.showForm = false;
          state.showValues = false;
          state.showGli = true;
          state.gliNumber = item?.gli_number;
          state.itemType = item?.item_type;
          state.item_type_category = item?.item_type_category;
        })
      );
      dispatch(
        getCVDPendingList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getCVDCompletedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
        })
      );
      dispatch(
        getCVDMarkedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getCVDRejectedList({
          page: 1,
          per_page: 10,
          receipt_id: item?._id,
          process_slug: slug,
          status: 4,
        })
      );
    } else if (key === "Pending") {
      if (statusData.length === 0) {
        getStatusData().then((response) => {
          if (response?.data?.status_code === 200) {
            setStatusData(response?.data?.data);
          }
        });
      }
      startTimmer({ item_details_id: item?._id, process_slug: slug }).then(
        (response) => {
          if (timer) {
            setTimer(false);
            if (response?.data?.status_code === 200) {
              toast.success("Timer Started");
            } else {
              toast.error("Can't start timer");
            }
          }
        }
      );
      dispatch(
        updateConfig((state) => {
          state.certificateID = item?._id;
          state.itemID = item?.gli_number;
          if (item?.cvd_form_type === 1) {
            state.formType = "Form1";
          } else if (item?.cvd_form_type === 2) {
            state.formType = "Form2";
          } else {
            state.formType = "noForm";
          }
          state.showForm = true;
          state.showValues = false;
        })
      );
    } else if (key === "Passed") {
      dispatch(
        updateConfig((state) => {
          state.itemID = item?.gli_number;
          state.showForm = false;
          state.showValues = true;
        })
      );
      getFields(item?._id, slug).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              if (item?.cvd_form_type === 1) {
                state.valueType = 1;
              } else if (item?.cvd_form_type === 2) {
                state.valueType = 2;
              }
            })
          );
          // if (response?.data?.data?.cvd_form_type === "diamond_jewellery") {
          //   dispatch(updateConfig((state) => (state.valueType = 1)));
          // } else if (response?.data?.data?.item_type_slug === "diamond_loose") {
          //   dispatch(updateConfig((state) => (state.valueType = 2)));
          // }
        }
        dispatch(
          updateConfig(
            (state) => (state.allValues = response?.data?.data?.graded_values)
          )
        );
      });
    } else if (key === "Marked") {
      dispatch(
        updateConfig((state) => {
          state.itemID = item?.gli_number;
          state.showForm = false;
          state.showValues = true;
        })
      );
      getFields(item?._id, slug).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              if (item?.cvd_form_type === 1) {
                state.valueType = 1;
              } else if (item?.cvd_form_type === 2) {
                state.valueType = 2;
              }
            })
          );

          // if (response?.data?.data?.item_type_slug === "diamond_jewellery") {
          //   dispatch(updateConfig((state) => (state.valueType = 1)));
          // } else if (response?.data?.data?.item_type_slug === "diamond_loose") {
          //   dispatch(updateConfig((state) => (state.valueType = 2)));
          // }
        }
        dispatch(
          updateConfig(
            (state) => (state.allValues = response?.data?.data?.graded_values)
          )
        );
      });
    } else if (key === "Rejected") {
      dispatch(
        updateConfig((state) => {
          state.itemID = item?.gli_number;
          state.showForm = false;
          state.showValues = true;
        })
      );
      getFields(item?._id, slug).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              if (item?.cvd_form_type === 1) {
                state.valueType = 1;
              } else if (item?.cvd_form_type === 2) {
                state.valueType = 2;
              }
            })
          );
          // if (response?.data?.data?.item_type_slug === "diamond_jewellery") {
          //   dispatch(updateConfig((state) => (state.valueType = 1)));
          // } else if (response?.data?.data?.item_type_slug === "diamond_loose") {
          //   dispatch(updateConfig((state) => (state.valueType = 2)));
          // }
        }
        dispatch(
          updateConfig(
            (state) => (state.allValues = response?.data?.data?.graded_values)
          )
        );
      });
    }
  };

  useEffect(() => {
    if (assignedID) {
      dispatch(
        getCVDPendingList({
          page: pendingPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingPage]);
  useEffect(() => {
    if (assignedID) {
      dispatch(
        getCVDCompletedList({
          page: completedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedPage]);

  useEffect(() => {
    if (assignedID) {
      dispatch(
        getCVDRejectedList({
          page: rejectedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
          status: 4,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedPage]);

  useEffect(() => {
    if (assignedID) {
      dispatch(
        getCVDMarkedList({
          page: markedPage,
          per_page: 10,
          receipt_id: assignedID,
          process_slug: slug,
          status: 5,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedPage]);

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;

    dispatch(updateConfig((state) => (state.search = searchValue)));
  };

  // Inside your component
  const handleSearch = () => {
    const searchValue = search;
    dispatch(
      updateConfig((state) => {
        state.cvdData.Pending = [];
        state.cvdData.Passed = [];
        state.cvdData.Assigned = [];
        state.cvdData.Rejected = [];
        state.cvdData.Marked = [];
      })
    );
    dispatch(
      getCVDAssignedList({
        search: searchValue,
        page: 1,
        process_slug: slug,
      })
    );
    if (assignedID) {
      dispatch(
        getCVDPendingList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getCVDCompletedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
        })
      );
      dispatch(
        getCVDMarkedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 5,
        })
      );
      dispatch(
        getCVDRejectedList({
          search: searchValue,
          page: 1,
          receipt_id: assignedID,
          process_slug: slug,
          status: 4,
        })
      );
    }
  };

  const handleConfirm = (values) => {
    if (formType === "Form1") {
      let formData = new FormData();
      formData.append("item_details_id", certificateID);
      formData.append("status", values?.status);
      formData.append("remarks", values?.remark);
      if (values?.status != 2) {
        formData.append("rft_diamond_number", values?.rft_no);
        formData.append("percentage", values?.percentage);
        formData.append("image", values?.image);
      }
      submitForm(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Successfully submitted");
          dispatch(updateConfig((state) => (state.showForm = false)));
          dispatch(
            updateConfig((state) => {
              state.cvdData.Pending = [];
              state.cvdData.Passed = [];
              state.cvdData.Rejected = [];
              state.cvdData.Marked = [];
            })
          );
          dispatch(
            getCVDPendingList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getCVDCompletedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getCVDRejectedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 4,
            })
          );
          dispatch(
            getCVDMarkedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 5,
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      let formData = new FormData();
      formData.append("item_details_id", certificateID);
      formData.append("status", values?.status2);
      formData.append("remarks", values?.remark);
      formData.append("cvd[weight]", values?.cvd_weight);
      formData.append("cvd[diamond_number]", values?.cvd_d_no);
      formData.append("cvd[percentage]", values?.cvd_percentage);
      formData.append("cvd_pass[weight]", values?.cvd_pass_weight);
      formData.append("cvd_pass[diamond_number]", values?.cvd_pass_d_no);
      formData.append("cvd_pass[percentage]", values?.cvd_pass_percentage);
      formData.append("hpht[weight]", values?.htpt_weight);
      formData.append("hpht[diamond_number]", values?.htpt_d_no);
      formData.append("hpht[percentage]", values?.htpt_percentage);
      formData.append("rft[weight]", values?.rft_weight);
      formData.append("rft[diamond_number]", values?.rft_d_no);
      formData.append("rft[percentage]", values?.rft_percentage);
      submitForm(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Sucessfully submitted");
          dispatch(updateConfig((state) => (state.showForm = false)));
          dispatch(
            updateConfig((state) => {
              state.cvdData.Pending = [];
              state.cvdData.Passed = [];
              state.cvdData.Rejected = [];
              state.cvdData.Marked = [];
            })
          );
          dispatch(
            getCVDPendingList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getCVDCompletedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
            })
          );
          dispatch(
            getCVDRejectedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 4,
            })
          );
          dispatch(
            getCVDMarkedList({
              page: 1,
              per_page: 10,
              receipt_id: assignedID,
              process_slug: slug,
              status: 5,
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      cvdData?.Pending?.length !== total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Assigned" &&
      cvdData?.Assigned?.length !== total?.Assigned
    ) {
      setAssignedPage(assignedPage + 1);
    }
    if (mouseHover === "Passed" && cvdData?.Passed?.length !== total?.Passed) {
      setCompletedPage(completedPage + 1);
    }
    if (mouseHover === "Marked" && cvdData?.Marked?.length !== total?.Marked) {
      setMarkedPage(markedPage + 1);
    }
    if (
      mouseHover === "Rejected" &&
      cvdData?.Rejected?.length !== total?.Rejected
    ) {
      setRejectedPage(rejectedPage + 1);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvdPendingStatus, cvdAssignedStatus, cvdCompletedStatus]);

  const handlePrintClick = (valueType) => {
    getCVDReport(assignedID).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.printData = response?.data?.data))
        );
        if (valueType === 1) {
          const printReport = () => {
            const element = form1PrintRef?.current;
            html2pdf(element, {
              margin: [0, 0, 0, 0],
              filename: `${
                "CVD Report Jewellery" +
                "-" +
                moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
              }.pdf`,
              image: { type: "jpeg", quality: 1 },
              html2canvas: {
                dpi: 100,
                letterRendering: true,
                useCORS: true,
                logging: true,
                scale: 4,
                scrollY: 0,
              },
              jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
              pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
              DisablePdfCompression: true,
            })
              .from("element-to-print")
              .outputPdf() // add this to replace implicite .save() method, which triggers file download
              .get("pdf")
              .then(function (pdfObj) {
                pdfObj.autoPrint();
                window.open(pdfObj.output("bloburl"), "F");
              });
          };
          setTimeout(() => {
            printReport();
          }, 500);
        } else if (valueType === 2) {
          const printReport = () => {
            const element = form2PrintRef?.current;
            html2pdf(element, {
              margin: [5, 0, 0, 0],
              filename: `${
                "CVD Report Loose" +
                "-" +
                moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
              }.pdf`,
              image: { type: "jpeg", quality: 1 },
              html2canvas: {
                dpi: 100,
                letterRendering: true,
                useCORS: true,
                logging: true,
                scale: 4,
                scrollY: 0,
              },
              jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
              pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
              DisablePdfCompression: true,
            })
              .from("element-to-print")
              .outputPdf() // add this to replace implicite .save() method, which triggers file download
              .get("pdf")
              .then(function (pdfObj) {
                pdfObj.autoPrint();
                window.open(pdfObj.output("bloburl"), "F");
              });
          };
          setTimeout(() => {
            printReport();
          }, 500);
        }
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    cvdData,
    formType,
    itemID,
    showForm,
    showValues,
    valueType,
    allValues,
    showGli,
    gliNumber,
    showCvdConfirmModal,
    isLoading,
    totalLength: {
      Pending: total?.Pending,
      Assigned: total?.Assigned,
      Passed: total?.Passed,
      Rejected: total?.Rejected,
      Marked: total?.Marked,
    },
    statusData,
    activeCard,
    form1PrintRef,
    form2PrintRef,
    apiStatus: {
      Pending: cvdPendingStatus,
      Assigned: cvdAssignedStatus,
      Passed: cvdCompletedStatus,
      Rejected: cvdRejectedStatus,
      Marked: cvdMarkedStatus,
    },
    handlePrintClick,
    handleSearchInput,
    handleLoadMore,
    setIsLoading,
    handleSearch,
    handleCardClick,
    handleConfirm,
  };
};

export default useCVD;
