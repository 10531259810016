import {
  ConfirmationBox,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderCardListing from "../../Global/OrderCardListing";
import Style from "./qc.module.scss";
import useQC from "./useQC";
import CompareTab from "./CompareTab";
import AddStatus from "../../Global/AddStatus";
import GliList from "./GliList";
import ShowForm from "./ShowForm";
import EnterMoreForm from "./EnterMoreForm";
import ShowFormTable from "./ShowFormTable";
import VersionTab from "./VersionTab";
import DisabledTabs from "./CompareTab/DisabledTabs";

const QCRecheck = () => {
  const {
    qcDataRecheck,
    formik,
    recheckTab,
    showForm,
    showQcModal,
    activeCard,
    formData,
    reportRef,
    showValues,
    itemID,
    showGli,
    gliNumber,
    isLoading,
    totalLength,
    handleConfirmModal,
    statusData,
    imagePreview,
    apiStatus,
    certificateNumber,
    enterMoreFormModal,
    enterMoreModal,
    showMoreFormTable,
    isNewDesc,
    descriptions,
    handleStatusChange,
    handleSearchInput,
    handleDescChange,
    handleAddRemarkkCardClick,
    handleEnterMoreFormModalClose,
    handleEnterMoreModalClose,
    handleEnterMoreYes,
    handleCardClick,
    getFieldError,
    handleSearch,
    handleConfirm,
    handleImageChange,
    handleLoadMore,
    setIsLoading,
  } = useQC();

  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div
            className={`col-xxl-5 col-5 pro-pt-4 ${Style.card_main_wrapper}`}
          >
            <div className="pro-mb-4">
              <SearchFilters
                showDateRange={false}
                onSearchInput={handleSearchInput}
                showFilters={true}
                showActions={false}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
              />
            </div>
            <div className={`col-auto`}>
              <button
                className="pro-btn pro-btn-primary lg"
                onClick={handleSearch}
                type="button"
              >
                <span className="material-symbols-outlined">search</span>
                Search
              </button>
            </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                data={qcDataRecheck}
                activeCard={activeCard}
                handleChange={handleCardClick}
                hasButton={false}
                isLoading={isLoading}
                totalLength={totalLength}
                setIsLoading={setIsLoading}
                handleLoadMore={handleLoadMore}
                apiStatus={apiStatus}
              />
            </div>
          </div>
          {showForm ? (
            <div className={`col pro-ps-0 ${Style.compare_wrap}`}>
              <VersionTab />
              <div className={`pro-ms-4 pro-d-flex pro-flex-column`}>
                {recheckTab?.activeTabId === 1 ? (
                  <ShowForm
                    formik={formik}
                    itemID={itemID}
                    certificateNumber={certificateNumber}
                    formData={formData}
                    getFieldError={getFieldError}
                    statusData={statusData}
                    imagePreview={imagePreview}
                    isNewDesc={isNewDesc}
                    descriptions={descriptions}
                    handleStatusChange={handleStatusChange}
                    handleImageChange={handleImageChange}
                    handleDescChange={handleDescChange}
                    handleAddRemarkkCardClick={handleAddRemarkkCardClick}
                  />
                ) : (
                  <DisabledTabs />
                )}
              </div>
            </div>
          ) : showValues ? (
            // <div className="col">
            //   <h3>{itemID}</h3>
            //   {Object?.keys(allValues || {})?.map((key) => {
            //     return (
            //       <div Style={{ display: "flex" }}>
            //         <p>
            //           {key} : {allValues[key]}
            //         </p>
            //       </div>
            //     );
            //   })}
            // </div>
            // changes to compare tag to migrate qc and exel entry
            <div className={`col pro-ps-0 ${Style.compare_wrap}`}>
              <CompareTab reportRef={reportRef} activeCard={activeCard} />
            </div>
          ) : showGli ? (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <GliList gliNumber={gliNumber} />
            </div>
          ) : showMoreFormTable ? (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <ShowFormTable />
            </div>
          ) : (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <h5 className="pro-ttl h5 pro-mb-0">Select One</h5>
            </div>
          )}
        </div>
      </div>
      <ModalLayout
        show={showQcModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
          />
        </div>
      </ModalLayout>
      <ModalLayout
        show={enterMoreModal}
        handleClose={handleEnterMoreModalClose}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Do you want to enter more details?"}
            subTitle={"Click yes to continue"}
            cancelText={"No"}
            cancelAction={handleEnterMoreModalClose}
            submitText={"Yes"}
            submitAction={handleEnterMoreYes}
            isRight={true}
          />
        </div>
      </ModalLayout>

      <OffCanvasLayout
        show={enterMoreFormModal}
        handleClose={handleEnterMoreFormModalClose}
        title="Enter more details"
        backdrop="static"
      >
        <EnterMoreForm />
      </OffCanvasLayout>
    </>
  );
};

export default QCRecheck;
