import {
  Button,
  ConfirmationBox,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useVerification from "./useVerification";
import Address from "./Address";
import Items from "./Items";
import Services from "./Services";
import Engraving from "./Engraving";
import Style from "../verifications.module.scss";
import ApprovedDetails from "./ApprovedDetails";
import Filter from "./Filter";
import OrderCardListing from "../../Global/OrderCardListing";
import BarCodePrint from "./StikerPrint/BarCodePrint";
import OfficeSlip from "./OfficeSlip/OfficeSlip";
import StockReceipt from "./StockReceipt/StockReceipt";

const Verification = () => {
  const {
    state,
    states,
    filterShow,
    showApproved,
    gliNumber,
    showDetail,
    activeCard,
    isLoading,
    totalLength,
    barCodeRef,
    officeSlipRef,
    apiStatus,
    StockReceiptRef,
    handleLoadMore,
    handleSearchInput,
    setIsLoading,
    handleSearch,
    handleChange,
    setFilterShow,
    handleReject,
    handleApprove,
    handleApproveRequest,
    handleRejectRequest,
  } = useVerification();
  return (
    <>
      <div className="row pro-mb-4 gx-2">
        <div className="col-auto">
          <SearchFilters
            showDateRange={false}
            onSearchInput={handleSearchInput}
            showFilters={true}
            showActions={false}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            searchInputProps={{ placeholder: "Search By GLI Number" }}
          />
        </div>
        <div className={`col-auto`}>
          <button
            className="pro-btn pro-btn-primary lg"
            onClick={handleSearch}
            type="button"
          >
            <span className="material-symbols-outlined">search</span>
            Search
          </button>
        </div>
        <div className="col">
          <button
            className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn}`}
            onClick={() => setFilterShow(true)}
          >
            <span className="material-symbols-outlined">tune</span>
            <span>Filter</span>
          </button>
        </div>
      </div>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-xxl-4 col-5 ${Style.card_main_wrapper}`}>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                isLoading={isLoading}
                activeCard={activeCard}
                data={states}
                totalLength={totalLength}
                extaClassName={Style.Verification_list}
                handleChange={handleChange}
                setIsLoading={setIsLoading}
                refBottom
                handleLoadMore={handleLoadMore}
                apiStatus={apiStatus}
              />
            </div>
          </div>
          {showDetail === true ? (
            <div className={`col pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper} pro-pe-3`}>
                <div className={`row gx-3 ${Style.multicolumn_wrapper}`}>
                  <div className="col-xxl-6">
                    <Address state={state} />
                  </div>
                  <div className="col-xxl-6">
                    <Services state={state} />
                  </div>
                </div>
                <div
                  className={`row gx-3 ${Style.multicolumn_wrapper} pro-mb-4`}
                >
                  <div className="col-xxl-6">
                    <Items state={state} />
                  </div>
                  <div className="col-xxl-6">
                    {state?.pendingData?.is_engraving !== false ? (
                      <Engraving state={state} />
                    ) : null}
                  </div>
                </div>
                <div
                  className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-pe-5 ${Style.footer_btn_wrap}`}
                >
                  <Button
                    className={"pro-btn-link lg pro-px-5"}
                    onClick={handleReject}
                  >
                    Reject
                  </Button>

                  <Button
                    className={"pro-btn-primary lg pro-ms-3"}
                    type="button"
                    onClick={handleApprove}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            </div>
          ) : showApproved === true ? (
            <div className={`col ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                <ApprovedDetails
                  gliNumber={gliNumber}
                  barCodeRef={barCodeRef}
                  officeSlipRef={officeSlipRef}
                  StockReceiptRef={StockReceiptRef}
                />
              </div>
            </div>
          ) : null}
        </div>
        <OffCanvasLayout
          centered={true}
          show={filterShow}
          handleClose={() => {
            setFilterShow(false);
          }}
          title={"Filter"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setFilterShow} />
        </OffCanvasLayout>
        <ModalLayout show={state?.approveModal} handleClose={handleApprove}>
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to Approve?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleApprove}
              submitText={"Yes"}
              submitAction={handleApproveRequest}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={state?.rejectModal} handleClose={handleReject}>
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to reject?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleReject}
              submitText={"Yes"}
              submitAction={handleRejectRequest}
              isRight={true}
            />
          </div>
        </ModalLayout>
      </div>
      <div className={Style.print}>
        <BarCodePrint barCodeRef={barCodeRef} />
        <OfficeSlip officeSlipRef={officeSlipRef} />
        <StockReceipt stockPrintRef={StockReceiptRef} />
      </div>
    </>
  );
};

export default Verification;
