// import QRCode from "react-qr-code";
// import Barcode from "react-barcode";
import { useCardPrint } from "./useCardPrint";
import JsBarcode from 'jsbarcode';
import { createCanvas } from 'canvas';
import QR from "qrcode-base64";

export const StaticHtmlTemplateC = ({value}) => {

  const { renderText } = useCardPrint();

  const arrayItems = value?.data?.graded_values;
  const topArray = arrayItems?.filter(item=>item?.slug !== "comments" && item?.slug !== "conclusion");
  const bottomArray = arrayItems?.filter(item=>item?.slug === "comments" || item?.slug === "conclusion");

    //to change the length of key
    const maxKeyLength = arrayItems?.reduce((max, str) => Math.max(max, str?.text?.length), 0);
    const newKeyValue = {
      ...styles.key,
      width : maxKeyLength > 14 ? 78 : 73,
    }
    //end to change the length of key

    const generateBarcodeBase64 = (value) => {
      const canvas = createCanvas();
      JsBarcode(canvas, value, {
        format: "CODE128", // Barcode type
        width: 1,
        height: 15,
        margin : 0,
        displayValue: false // Set to true if you want to display the number below
      });
    
      // Convert the canvas to a base64 string
      return canvas.toDataURL("image/png");
    };
    
    const barcodeData = generateBarcodeBase64(value?.certificate_number);

    var qrData = QR.drawImg(
      value?.qr,
      {
        typeNumber: 5,
        errorCorrectLevel: "M",
        size: 37
      }
    );

  return (
    <>
      <div 
        style={{
        ...styles.outer_wrapper, 
        }}>
          <img style={styles.bg_image} src={value?.background_image} alt="background images"/>
        <div style={styles.header_wrap}>
            <img style={styles.header_img} src={value?.front_top_image || ""} alt="Header"/>
            {/* {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
              <span style={styles.qr_wrap}>
                <QRCode
                  value={value?.qr}
                  width={39.37}
                  height={39.37}
                  style={styles.qr_code}
                />
              </span>
            )} */}
            {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
            <span style={styles.qr_wrap}>
              <img src={qrData} alt="qrcode" style={styles.qr_code}/>
            </span>
          )}
        </div>
        <div style={styles.body}>
          <div style={{
            minHeight : topArray?.length <= 3 ? 45 : "auto",
          }}>
            <div style={styles.row}>
              <p style={newKeyValue}>
              {value?.bold === 1 ? (
                  <b style={{fontWeight : "600"}}>SUMMARY NO</b>
                ) : "SUMMARY NO"}
              </p>
              <p style={styles.separate}>:</p>
              <p style={styles.value}>
              {value?.bold === 1 ? (
                  <b style={{fontWeight : "600"}}>{value?.certificate_number}</b>
                ): value?.certificate_number}
              </p>
            </div>
            {topArray?.map((item,index)=>(
              <div style={styles.row} key={index}>
                <p style={newKeyValue}>
                  {item?.bold === 1 ? (
                    <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                    ) : renderText(item?.text, item?.key_font_case)
                  }
                </p>
                <p style={styles.separate}>:</p>
                <p style={styles.value}>
                  {item?.bold === 1 ? (
                    <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                    ) : renderText(item?.value, item?.value_font_case)
                  }
                </p>
              </div>
            ))}
          </div>
          <div style={styles.middle_wrap}>
            <div style={styles.content_wrap}>
              <div style={styles.inner_table_wrap}>
                <div style={styles.centerstone_wrap}>
                  <p style={styles.centerstone_title}>CENTRE STONE</p>

                  {value?.data?.center_stone?.map((item,index)=>(
                    <div style={styles.row} key={index}>
                      <p style={styles.inner_key}>
                        {item?.bold === 1 ? (
                          <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                          ) : renderText(item?.text, item?.key_font_case)
                        }
                      </p>
                      <p style={styles.separate}>:</p>
                      <p style={styles.inner_value}>
                        {item?.bold === 1 ? (
                          <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                          ) : renderText(item?.value, item?.value_font_case)
                        }
                      </p>
                    </div>
                  ))}
                </div>
                <div style={styles.centerstone_wrap}>
                  <p style={styles.centerstone_title}>SIDE STONE</p>

                  {value?.data?.side_stone?.map((item,index)=>(
                    <div style={styles.row} key={index}>
                      <p style={styles.inner_key}>
                        {item?.bold === 1 ? (
                          <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                          ) : renderText(item?.text, item?.key_font_case)
                        }
                      </p>
                      <p style={styles.separate}>:</p>
                      <p style={styles.inner_value}>
                        {item?.bold === 1 ? (
                          <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                          ) : renderText(item?.value, item?.value_font_case)
                        }
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={styles.img_wrap}>
                <img style={styles.main_img} src={value?.image || ""} alt="Card images" />
            </div>
          </div>
          <div style={styles.bottom_wrap}>
            {bottomArray?.map((item,index)=>(
              <div style={styles.row} key={index}>
                <p style={styles.bottom_key}>
                  {item?.bold === 1 ? (
                    <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                    ) : renderText(item?.text, item?.key_font_case)
                  }
                </p>
                <p style={styles.separate}>:</p>
                <p style={styles.bottom_value}>
                  {item?.bold === 1 ? (
                    <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                    ) : renderText(item?.value, item?.value_font_case)
                  }
                </p>
              </div>
            ))}
          </div>
        </div>
        <div style={styles.footer_wrap}>
          {
            parseInt(value?.footer_type) !== 2 ?(
              <div style={styles.barcode_wrap}>
                {value?.certificate_number && (
                  // <Barcode
                  // value={value?.certificate_number}
                  // height={15}
                  // width={0.5}
                  // displayValue={false}
                  // margin={0}
                  // />
                  <img src={barcodeData} alt="barcode" style={styles.barcode} />
                )}
              </div>
              ) :(
              <img style={styles.footer_img} src={value?.front_foot_image || ""} alt="Card footer"/>
            )
          }
        </div>
      </div>
    </>
  );
};

const styles = {
  outer_wrapper:  {
    width  :321,
    minWidth : 321,
    maxWidth : 321,
    height : 207,
    maxHeight : 207,
    minHeight : 207,
    fontSize : 7.5,
    border  : '1px solid #f3f3f3',
    color : "#000000",
    overflow: "hidden",
    fontFamily: "Graphik",
    backgroundColor: "#ffffff",
    position: "relative",
  },
  header_wrap : {
    display : "flex",
    alignItems : "center",
    maxWidth : '100%',
    height : 47.24,
    // height : '1.2cm'
    width : '100%',
    position : 'relative',
  },
  header_img :{
    maxWidth : 321,
    width : '100%',
    maxHeight : 47.24,
    height : 47.24,
  },
  qr_wrap : {
    width : 39.37, 
    height : 39.37, 
    display : "inline-block", 
    position : "absolute",
    top : 2,
    right : 10,
  },
  qr_code : {
    width: 39.37,
    height: 39.37,
  },
  barcode_wrap: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    lineHeight: 0,
    width: "100%",
    display: "flex",
    alignItems : "center",
    justifyContent: "center",
  },
  footer_wrap : {
    height : 15,
    // height : '0.8cm'
    width : '100%',
    display : "flex",
    position : 'relative',
  },
  footer_img :{
    width : '100%',
    maxHeight : 15,
    height : 15,
  },
  body: {
    height : 141.76,
    maxHeight : 141.76,
    padding : '1px 7px',
    display : "flex",
    flexDirection : "column",
    position : 'relative',
  },
  middle_wrap: {
    display : 'flex',
    padding : '1px 0',
    // flex :"1",
    // alignItems : "center",
  },
  row :{
    display : 'flex',
    padding : '0.9px 0px'
  },
  key: {
    width: 70,
    fontSize: 7.8,
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom:0,
  },
  value: {
    flex : "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom:0,
    fontSize : 7.8,
  },
  bottom_key: {
    width: 65,
    fontSize: 7.8,
    fontWeight: 400,
    lineHeight: "1.46",
    marginBottom:0,
  },
  bottom_value: {
    flex : "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.46",
    marginBottom:0,
    fontSize : 7.5,
  },
  inner_key: {
    width: 55,
    fontSize: 7,
    fontWeight: 400,
    lineHeight: "1.35",
    marginBottom:0,
  },
  inner_value: {
    flex : "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.35",
    marginBottom:0,
    fontSize : 7,
  },
  separate: {
    width: 10,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight : 400,
    fontSize:  7.5,
    lineHeight: "1.35",
    marginBottom:0,
    position: "relative",
    top : -1,
  },
  content_wrap :{
    flex : "1",
  },
  img_wrap : {
    width : 90,
    marginRight: 4,
  },
  main_img :{
    width: '100%',
    maxWidth : 90,
    height : 60,
    maxHeight : 60,
  },
  inner_table_wrap :{
    display : "flex",
  },
  centerstone_wrap : {
    width : '50%',
  },
  centerstone_title : {
    marginBottom: 0,
    fontSize : 7.5,
    fontWeight : 500,
    textAlign : "center",
  },
  sidestone_wrap : {
    width : '50%',
  },
  barcode_wrap: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    lineHeight: 0,
    width : "100%",
    display: "flex",
    alignItems : "center",
    justifyContent: "center",
  },
  bg_image: {
    position : "absolute",
    top : 0,
    left : 0,
    width : "100%",
    height : "100%",
  },
};
