/* eslint-disable eqeqeq */
import {
  ConfirmationBox,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderCardListing from "../../Global/OrderCardListing";
import Style from "./qc.module.scss";
import useQC from "./useQC";
import CompareTab from "./CompareTab";
import ReportPrint from "./ReportPrint/ReportPrint";
import GliList from "./GliList";
import ShowForm from "./ShowForm";
import EnterMoreForm from "./EnterMoreForm";
import ShowFormTable from "./ShowFormTable";

const QC = () => {
  const {
    qcData,
    formik,
    showForm,
    formData,
    showValues,
    itemID,
    isLoading,
    totalLength,
    statusData,
    activeCard,
    showGli,
    imagePreview,
    gliNumber,
    reportRef,
    apiStatus,
    enterMoreModal,
    enterMoreFormModal,
    certificateNumber,
    showMoreFormTable,
    isNewDesc,
    descriptions,
    setIsLoading,
    handleSearchInput,
    handleSearch,
    getFieldError,
    handleLoadMore,
    handleCardClick,
    handleDescChange,
    handleImageChange,
    handleStatusChange,
    handleEnterMoreYes,
    handleEnterMoreModalClose,
    handleAddRemarkkCardClick,
    handleEnterMoreFormModalClose,
  } = useQC();

  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div
            className={`col-xxl-5 pro-pt-4 col-5 ${Style.card_main_wrapper}`}
          >
            <div className="pro-mb-4">
              <SearchFilters
                showDateRange={false}
                onSearchInput={handleSearchInput}
                showFilters={true}
                showActions={false}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
              />
              <div className={`col-auto`}>
                <button
                  className="pro-btn pro-btn-primary lg"
                  onClick={handleSearch}
                  type="button"
                >
                  <span className="material-symbols-outlined">search</span>
                  Search
                </button>
              </div>
            </div>

            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                hasButton={false}
                data={qcData}
                isLoading={isLoading}
                totalLength={totalLength}
                activeCard={activeCard}
                setIsLoading={setIsLoading}
                handleChange={handleCardClick}
                refBottom
                handleLoadMore={handleLoadMore}
                apiStatus={apiStatus}
              />
            </div>
          </div>
          {showForm ? (
            <ShowForm
              formik={formik}
              itemID={itemID}
              formData={formData}
              statusData={statusData}
              imagePreview={imagePreview}
              isNewDesc={isNewDesc}
              certificateNumber={certificateNumber}
              getFieldError={getFieldError}
              handleImageChange={handleImageChange}
              handleDescChange={handleDescChange}
              handleStatusChange={handleStatusChange}
              handleAddRemarkkCardClick={handleAddRemarkkCardClick}
              descriptions={descriptions}
            />
          ) : showValues ? (
            // <div className="col">
            //   <h3>{itemID}</h3>
            //   {Object?.keys(allValues || {})?.map((key) => {
            //     return (
            //       <div Style={{ display: "flex" }}>
            //         <p>
            //           {key} : {allValues[key]}
            //         </p>
            //       </div>
            //     );
            //   })}
            // </div>
            // changes to compare tag to migrate qc and exel entry
            <div className={`col pro-ps-0 ${Style.compare_wrap}`}>
              <CompareTab reportRef={reportRef} activeCard={activeCard} />
            </div>
          ) : showGli ? (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <GliList gliNumber={gliNumber} />
            </div>
          ) : showMoreFormTable ? (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <ShowFormTable />
            </div>
          ) : (
            <div className={`col pro-pt-4  ${Style.compare_wrap}`}>
              <h5 className="pro-ttl h5 pro-mb-0">Select One</h5>
            </div>
          )}
        </div>
      </div>
      <div className={Style.print}>
        <ReportPrint reportRef={reportRef} />
      </div>
      <ModalLayout
        show={enterMoreModal}
        handleClose={handleEnterMoreModalClose}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Do you want to enter more details?"}
            subTitle={"Click yes to continue"}
            cancelText={"No"}
            cancelAction={handleEnterMoreModalClose}
            submitText={"Yes"}
            submitAction={handleEnterMoreYes}
            isRight={true}
          />
        </div>
      </ModalLayout>

      <OffCanvasLayout
        show={enterMoreFormModal}
        handleClose={handleEnterMoreFormModalClose}
        title="Enter more details"
        backdrop="static"
      >
        <EnterMoreForm />
      </OffCanvasLayout>
    </>
  );
};

export default QC;
