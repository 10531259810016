import { SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./photoSectionReissue.module.scss";
import OrderCardListing from "../../Global/OrderCardListing";
import usePhotoSectionReissue from "./usePhotoSectionReissue";
import PhotoSectionTable from "./PhotoSectionTable";

const PhotoSectionReissue = () => {
  const {
    activeCard,
    lastCard,
    apiStatus,
    cardReissueData,
    totalLength,
    isLoading,
    photoSectionState,
    setIsLoading,
    handleSearchInput,
    handleCardClick,
    handleLoadMore,
    handleSearch,
  } = usePhotoSectionReissue();
  return (
    <div className={Style.varifaication_main_wrap}>
      <div className="row">
        <div className={`col-4 ${Style.card_main_wrapper}`}>
          <div className="row pro-mb-4 gx-2">
            <div className="col-auto">
              <SearchFilters
                showDateRange={false}
                onSearchInput={handleSearchInput}
                showFilters={true}
                showActions={false}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
              />
            </div>
            <div className={`col-auto`}>
              <button
                className="pro-btn pro-btn-primary lg"
                onClick={handleSearch}
                type="button"
              >
                <span className="material-symbols-outlined">search</span>
                Search
              </button>
            </div>
          </div>
          <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
            <OrderCardListing
              isLoading={isLoading}
              data={cardReissueData}
              lastCard={lastCard}
              handleChange={handleCardClick}
              handleLoadMore={handleLoadMore}
              setIsLoading={setIsLoading}
              activeCard={activeCard}
              totalLength={totalLength}
              extaClassName={Style.print_list}
              apiStatus={apiStatus}
            />
          </div>
        </div>
        {photoSectionState?.showTable ? (
          <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
            <div className={`${Style.table_flow_wrapper}`}>
              <PhotoSectionTable gliNumber={photoSectionState?.gli_id} />
            </div>
          </div>
        ) : (
          <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
            <div className={`${Style.table_flow_wrapper}`}>
              <h6 className="pro-ttl h6">Select one</h6>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoSectionReissue;
