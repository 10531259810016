import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getPrintingList,
  updateConfig,
} from "../../../store/slices/Printing/printingSlice";
import { startTimer } from "../api";
import { toast } from "react-toastify";

const usePrinting = () => {
  const visitingOneFrontPrintRef = useRef();
  const visitingOneBackPrintRef = useRef();
  const papperFrontPrintRef = useRef();
  const papperBackPrintRef = useRef();
  const bigFrontPrintRef = useRef();
  const vistingDuplexRef = useRef();
  const papperDuplexRef = useRef();
  const foldableCardRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const dispatch = useDispatch();
  const printingState = useSelector((state) => state.printing);
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
        state.search = "";
      })
    );
    dispatch(
      getPrintingList({
        page: 1,
        per_page: 10,
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (pendingPage !== 1) {
      if (
        printingState.cardData.Pending?.length <= printingState.total?.Pending
      ) {
        dispatch(
          getPrintingList({
            page: pendingPage,
            per_page: 10,
            process_slug: slug,
            search: printingState.search,
          })
        );
      } else if (
        printingState.cardData.Pending?.length > printingState.total?.Pending
      ) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [pendingPage]);

  const handleSearchInput = (searchValue) => {
    dispatch(
      updateConfig((state) => (state.search = searchValue?.target?.value))
    );
  };

  const handleSearch = () => {
    setPendingPage(1);
    const searchValue = printingState?.search;
    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
        state.currentPage = 1;
      })
    );
    dispatch(
      getPrintingList({
        search: searchValue,
        page: 1,
        process_slug: slug,
        per_page: 10,
      })
    );
  };

  const startTimerPrinting = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimer(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleCardClick = (e) => {
    setActiveCard({ id: e._id, key: "Pending" });
    startTimerPrinting(e);
    dispatch(
      updateConfig((state) => {
        state.showTable = true;
        state.selectedId = [e?._id];
        state.gli_id = e?.gli_number;
        state.currentPage = 1;
        state.clear = true;
        state.isMultiPrint = false;
      })
    );
    const timmerId = setTimeout(() => {
      dispatch(updateConfig((state) => (state.clear = false)));
      clearTimeout(timmerId);
    }, 100);
  };

  const handleModalClick = () => {
    dispatch(updateConfig((state) => (state.printModal = !state.printModal)));
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      printingState?.cardData?.Pending?.length <= printingState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
      dispatch(
        updateConfig((state) => {
          state.currentPage = pendingPage + 1;
        })
      );
    }
  };

  const handleMultiClick = (status) => {
    dispatch(
      updateConfig((state) => {
        state.isMultiGliPrint = !state.isMultiGliPrint;
        state.isMultiPrint = status ? true : false;
      })
    );
  };

  return {
    cardData: printingState?.cardData,
    visitingOneFrontPrintRef,
    visitingOneBackPrintRef,
    papperFrontPrintRef,
    papperBackPrintRef,
    bigFrontPrintRef,
    foldableCardRef,
    printingState,
    vistingDuplexRef,
    papperDuplexRef,
    totalLength: { Pending: printingState.total.Pending },
    apiStatus: { Pending: printingState?.pendingCardStatus },
    isLoading,
    activeCard,
    handleSearch,
    handleSearchInput,
    setIsLoading,
    handleLoadMore,
    handleCardClick,
    handleModalClick,
    handleMultiClick,
  };
};

export default usePrinting;
